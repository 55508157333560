import React from "react"
import {
  BlogCard,
  CarousellLeft,
  CarousellRight,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
  BlogFlex,
  Arrow,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../Assets/word.avif"
import BlogImage2 from "../../Assets/excel.avif"
import BlogImage3 from "../../Assets/saveExcel.avif"
import BlogImage4 from "../../Assets/csv.avif"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-word/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            How to Convert PDF to Word for Free: Windows, Mac, Mobile (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to Word for free on Windows, Mac, iPhone, &
            Android! The best free tool for 2023.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-excel/")}
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>
            {" "}
            How to Convert PDF to Excel (2023): Windows, Mac, Mobile, Online
          </BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to Excel on Windows, Mac, and mobile! Free,
            online, or with a PDF editor. Including the best free tool of 2023.
          </BlogDescription>
          <BlogReadTime>9 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-save-excel-as-pdf/")}
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Save an Excel File as a PDF</BlogTitle>
          <BlogDescription>
            Learn how to save your Excel workbook as a PDF, save certain cells
            as PDF, and even specific sheets as PDF, for free! Step-by-step
            photos and instructions.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-csv/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>
            How to Convert PDF to CSV on Windows & Mac (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to CSV on Windows and Mac in 2023!
            Step-by-step instructions in our brand new guide.
          </BlogDescription>
          <BlogReadTime>6 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
